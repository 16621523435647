import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "../components/common";
import { Team, MeetTheTeam } from "../components";

import { useDocumentTitle } from "../hooks";

export const TeamPage = () => {
  const params = useParams();

  useDocumentTitle(
    `Pittbos - Contractor Estimating Platform - Team ${params.id
      .split("-")
      .map((item) => item.replace(/./, (c) => c.toUpperCase()))
      .join(" ")}`
  );

  return (
    <React.Fragment>
      <Container
        backgroundWhite
        title="meet the team"
        othetStyles={{ marginTop: "150px" }}
      >
        <MeetTheTeam />
      </Container>
      <Team small />
    </React.Fragment>
  );
};
