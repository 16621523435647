import React, { useState } from 'react';
import { Container } from '../common';

import takeoffImage from '../../images/takeoff.png';
import proposalsImage from '../../images/proposals.png';
import createProjectImage from '../../images/create_project.png';
import pittbosCloudMobileImage from '../../images/about_pittbos_contractor_cloud_mobile.svg';
import pittbosCloudDesktopImage from '../../images/about_pittbos_contractor_cloud_desktop.svg';

import styles from './AboutPittbosContractor.module.css';
import { WatchDemoVideo } from './WatchDemoVideo';

export function AboutPittbosContractor() {
  const [isVisibleWatchADemo, setIsVisibleWatchADemo] = useState(false);

  return (
    <React.Fragment>
      <Container id="features" wrapperClasses={styles.wrapper}>
        <h2 className="title text-center">Pittbos <span className="text-red">Contractor</span></h2>
        <p className={styles.subTitle}>
          Our cloud-based platform allows you to manage your
          business from anywhere - your office, home, or worksite.
        </p>
        <div className={styles.desktopCloudImage}>
          <img src={pittbosCloudDesktopImage} alt="desktop cloud image"/>
        </div>
        <div className={styles.mobileCloudImage}>
          <img src={pittbosCloudMobileImage} alt="mobile cloud image"/>
        </div>
        <div className={styles.createProjectInfo}>
          <div className={styles.createProjectText}>
            <p>
              Pittbos Contractor enables creation of quality, accurate estimates & proposals, bid process tracking,
              customer service management, and invoice processing.
            </p>
            <p>
              With an affordable monthly subscription fee, no development costs, and complete onboarding & customer
              service support, Pittbos is the tool all asphalt contractors need.
            </p>
            <p>
              Please
              {' '}
              <span
                onClick={() => setIsVisibleWatchADemo(true)}
                className={styles.watchDemo}
              >
                watch our demo
              </span>
              {' '}
              video to review the full scope of
              advantages our Contractor platform can bring
              your business.
            </p>
          </div>
          <div className={styles.createProjectImage}>
            <img src={createProjectImage} alt="create project"/>
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.takeoffImage}>
            <img src={takeoffImage} alt=""/>
          </div>
          <div className={styles.proposalsImage}>
            <img src={proposalsImage} alt=""/>
          </div>
        </div>
      </Container>
      {isVisibleWatchADemo && (
        <WatchDemoVideo setIsVisible={setIsVisibleWatchADemo}/>
      )}
    </React.Fragment>
  );
}