import React, { useState } from "react";
import clsx from 'classnames';

import { Container } from "../common";
import { GetADemoModal } from "../GetADemoModal/GetADemoModal";
import { LearnMoreModal } from "../LearnMoreModal/LearnMoreModal";
import { AboutPittbosPlatform } from '../AboutPittbosPlatform';

import { ReactComponent as PittbosLogo } from "../../icons/pittbos_logo.svg";
import styles from "./HeroBlock.module.css";

const titleStyles = clsx("title text-white text-center", styles.title);

export function HeroBlock() {
  const [isVisibleLearnMore, setIsVisibleLearnMore] = useState(false);
  const [isVisibleRequestADemo, setIsVisibleRequestADemo] = useState(false);

  return (
    <React.Fragment>
      <div className={styles.containerBackground}>
        <Container wrapperClasses={styles.wrapper}>
          <div className={styles.logo}>
            <PittbosLogo/>
          </div>
          <p className={titleStyles}>Quality contracting & estimating software</p>
          <div className={styles.buttonWrapper}>
            <button
              className={clsx(styles.button, "button outline")}
              onClick={() => {
                setIsVisibleRequestADemo(true);
              }}
            >
              Request Demo
            </button>
            <button
              className="button text-white"
              onClick={() => {
                setIsVisibleLearnMore(true);
              }}
            >
              Learn More
            </button>
          </div>
          <p className={styles.subtitle}>
            Built <span className="text-white">by pavers</span> for pavers
          </p>
        </Container>
        <AboutPittbosPlatform/>
      </div>
      {isVisibleRequestADemo && (
        <GetADemoModal setIsVisible={setIsVisibleRequestADemo} />
      )}
      {isVisibleLearnMore && (
        <LearnMoreModal setIsVisible={setIsVisibleLearnMore} />
      )}
    </React.Fragment>
  );
}
