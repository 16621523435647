import React, { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "classnames";

import { Container } from "../Container";
import { GetADemoModal } from "../../GetADemoModal";

import { ReactComponent as PittbosLogo } from "../../../icons/pittbos_logo.svg";
import { ReactComponent as PittbosLogoBlack } from "../../../icons/pittbos_logo_black.svg";
import { ReactComponent as BurgerIcon } from "../../../icons/burger.svg";
import { ReactComponent as FeaturesIcon } from "../../../icons/features.svg";
import { ReactComponent as MissionIcon } from "../../../icons/mission.svg";
import { ReactComponent as TeamIcon } from "../../../icons/team.svg";
import { ReactComponent as PricingIcon } from "../../../icons/pricing.svg";
import { ReactComponent as CloseIcon } from "../../../icons/close.svg";
import { ReactComponent as BlogIcon } from "../../../icons/request_demo_icon.svg";

import { scrollToComponent } from "../../../utils";

import styles from "./Header.module.css";

const burgerIcons = {
  features: <FeaturesIcon />,
  mission: <MissionIcon />,
  team: <TeamIcon />,
  pricing: <PricingIcon />,
  blog: <BlogIcon />,
};

const isBlockOnTheScreen = (x, min, max) => x >= min && x <= max;

export const Header = () => {
  const [isVisibleRequestADemo, setIsVisibleRequestADemo] = useState(false);
  const [isVisibleBurger, setIsVisibleBurger] = useState(false);

  const { pathname } = useLocation();
  const isLocationDefault = useMemo(() => pathname === "/", [pathname]);

  const requestDemoStyles = clsx(styles.requestDemoButton, "button", "outline");
  const burgerStyles = clsx(styles.burger, {
    [styles.active]: isVisibleBurger,
  });
  const overlayStyles = clsx(styles.overlay, {
    [styles.active]: isVisibleBurger,
  });

  const featuresBlockOffset = document.getElementById("features")?.offsetTop;
  const limitsRaisedOffset =
    document.getElementById("limits_raised")?.offsetTop;
  const missionsBlockOffset = document.getElementById("mission")?.offsetTop;
  const industryBlockOffset = document.getElementById("industry")?.offsetTop;

  const navItems = useMemo(() => {
    return [
      {
        path: "/#features",
        title: "features",
        className: clsx(
          { [styles.navItem]: !isVisibleBurger },
          { [styles.navItemWithBurger]: isVisibleBurger },
          {
            [styles.active]: isBlockOnTheScreen(
              window.scrollY,
              featuresBlockOffset,
              limitsRaisedOffset
            ),
          },
          { "text-blue": !isLocationDefault }
        ),
        onClick: () => {
          isVisibleBurger && closeBurger();
          isLocationDefault &&
            scrollToComponent(document.getElementById("features"));
        },
      },
      {
        path: "/#mission",
        title: "mission",
        className: clsx(
          { [styles.navItem]: !isVisibleBurger },
          { [styles.navItemWithBurger]: isVisibleBurger },
          {
            [styles.active]: isBlockOnTheScreen(
              window.scrollY,
              missionsBlockOffset,
              industryBlockOffset
            ),
          },
          { "text-blue": !isLocationDefault }
        ),
        onClick: () => {
          isVisibleBurger && closeBurger();
          isLocationDefault &&
            scrollToComponent(document.getElementById("mission"));
        },
      },
      {
        path: "/team/mark-peach",
        title: "team",
        className: clsx(
          { [styles.navItem]: !isVisibleBurger },
          { [styles.navItemWithBurger]: isVisibleBurger },
          {
            [styles.active]: pathname.split("/")[1] === "team",
          },
          { "text-blue": !isLocationDefault }
        ),
        onClick: () => {
          isVisibleBurger && closeBurger();
        },
      },
      {
        path: "/pricing",
        title: "pricing",
        className: clsx(
          { [styles.navItem]: !isVisibleBurger },
          { [styles.navItemWithBurger]: isVisibleBurger },
          {
            [styles.active]: pathname === "/pricing",
          },
          { "text-blue": !isLocationDefault }
        ),
        onClick: () => {
          isVisibleBurger && closeBurger();
        },
      },
      {
        path: "/#blog",
        title: "blog",
        className: clsx(
          { [styles.navItem]: !isVisibleBurger },
          { [styles.navItemWithBurger]: isVisibleBurger },
          {
            [styles.active]: isBlockOnTheScreen(
              window.scrollY,
              missionsBlockOffset,
              industryBlockOffset
            ),
          },
          { "text-blue": !isLocationDefault }
        ),
        onClick: () => {
          isVisibleBurger && closeBurger();
          isLocationDefault &&
            scrollToComponent(document.getElementById("blog"));
        },
      },
    ];
  }, [
    featuresBlockOffset,
    industryBlockOffset,
    isLocationDefault,
    isVisibleBurger,
    limitsRaisedOffset,
    missionsBlockOffset,
    pathname,
  ]);

  const onLoginButtonClick = () => {
    window.open("https://app.pittbos.com/login", "_blank");
  };

  const openBurger = () => {
    setIsVisibleBurger(true);
  };

  const closeBurger = () => {
    setIsVisibleBurger(false);
  };

  return (
    <React.Fragment>
      <Container
        backgroundDarkBlue={isLocationDefault}
        backgroundWhite={!isLocationDefault}
        wrapperClasses={styles.wrapper}
        backgroundClasses={styles.background}
      >
        <NavLink
          to="/"
          onClick={() => {
            isLocationDefault && window.scrollTo({ top: 0 });
          }}
        >
          {isLocationDefault ? <PittbosLogo /> : <PittbosLogoBlack />}
        </NavLink>
        <div className={overlayStyles} onClick={closeBurger} />
        <div
          className={clsx(styles.navWrapper, {
            [styles.active]: isVisibleBurger,
          })}
        >
          {isVisibleBurger && (
            <div className={styles.burgerHeader}>
              <div>
                <PittbosLogoBlack />
              </div>
              <div className={styles.closeIcon}>
                <CloseIcon onClick={closeBurger} />
              </div>
            </div>
          )}
          {navItems.map((nav, index) => (
            <NavLink
              key={`${nav.title}-${index}`}
              to={nav.path}
              onClick={nav.onClick}
            >
              {isVisibleBurger ? (
                <div className={styles.navItemIcon}>
                  {burgerIcons[nav.title]}
                  <p className={nav.className}>{nav.title}</p>
                </div>
              ) : (
                <p className={nav.className}>{nav.title}</p>
              )}
            </NavLink>
          ))}
          <div className={styles.buttonMobileWrapper}>
            <button
              className={requestDemoStyles}
              onClick={() => {
                isVisibleBurger && closeBurger();
                setIsVisibleRequestADemo(true);
              }}
            >
              request demo
            </button>
            <button
              className="button outline large"
              onClick={onLoginButtonClick}
            >
              Log in
            </button>
          </div>
        </div>
        <div className={styles.headerButtonsWrapper}>
          <div className={styles.buttonDesktopWrapper}>
            <button
              className="button outline large"
              onClick={onLoginButtonClick}
            >
              Log in
            </button>
          </div>
          <BurgerIcon
            className={burgerStyles}
            fill={isLocationDefault ? "#ffffff" : "#07073d"}
            onClick={openBurger}
          />
        </div>
      </Container>
      {isVisibleRequestADemo && (
        <GetADemoModal setIsVisible={setIsVisibleRequestADemo} />
      )}
    </React.Fragment>
  );
};
