import React, { useState } from 'react';
import clsx from 'classnames';

import { Container } from '../common';
import { GetADemoModal } from '../GetADemoModal';
import { CustomerFollowUpItem, CustomerFollowUpItems } from './CustomerFollowUpItems';

import followUpCustomersImage from '../../images/follow_up_customers.png';
import visualAnalysisImage from '../../images/visual_analysis.png';
import { ReactComponent as RequestDemoIcon } from '../../icons/request_demo_icon.svg';

import styles from './CustomerFollowUp.module.css';

const titleStyles = clsx(styles.title, "title text-white text-center")

export function CustomerFollowUp() {
  const [isVisibleRequestADemo, setIsVisibleRequestADemo] = useState(false);

  return (
    <React.Fragment>
      <Container backgroundClasses={styles.container} wrapperClasses={styles.wrapper}>
        <h2 className={titleStyles}>
          Simple & easy
          {' '}
          <span className="text-red">customer follow up</span>
          {' '}
          and
          {' '}
          <span className="text-red">analytics</span>
        </h2>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageWithTitle}>
            <h3 >Email & Text Follow Up Customers</h3>
            <img src={followUpCustomersImage} alt="Email & Text Follow Up Customers"/>
          </div>
          <div className={styles.imageWithTitle}>
            <h3>Visual analysis</h3>
            <img src={visualAnalysisImage} alt="Visual analysis"/>
          </div>
        </div>
        <div className={styles.items}>
          <CustomerFollowUpItems/>
          <CustomerFollowUpItem
            titleColor="red"
            icon={RequestDemoIcon}
            title="Request a Demo"
            description="Get a demo of all of our current features and a sneak peak at what we’re working on."
          >
            <button onClick={() => setIsVisibleRequestADemo(true)} className="button text-white">
              Request Demo
            </button>
          </CustomerFollowUpItem>
        </div>
      </Container>
      {isVisibleRequestADemo && (
        <GetADemoModal setIsVisible={setIsVisibleRequestADemo} />
      )}
    </React.Fragment>
  )
}