import React from "react";
  import { Routes, Route, Navigate } from "react-router-dom";

import {LegalPage, MainPage, PricingPage, TeamPage} from "./pages";
import { Footer, Header } from "./components/common";
import { useScrollToTop } from "./hooks";

import "./index.css";

export const App = () => {
  useScrollToTop();

  return (
    <React.Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="team/:id" element={<TeamPage />} />
        <Route path="legal/:page" element={<LegalPage />} />
        <Route path="pricing" element={<PricingPage />} />        
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
};
