import React, { useState } from 'react';

import { Container } from '../common';
import { GetADemoModal } from '../GetADemoModal';

import styles from './WhyPittbosExists.module.css';

export function WhyPittbosExists() {
  const [isVisibleRequestADemo, setIsVisibleRequestADemo] = useState(false);

  return (
    <React.Fragment>
      <Container id="mission" backgroundClasses={styles.containerBackground} wrapperClasses={styles.wrapper}>
        <div className={styles.text}>
          <h2 className="title text-white">Why <span className="text-red">Pittbos exists:</span></h2>
          <p>
            Our mission at Pittbos is to streamline business for asphalt contractors & suppliers by providing a
            user-friendly, accurate, all-in-one sales management software solution.
          </p>
          <button
            className="button text-white"
            onClick={() => setIsVisibleRequestADemo(true)}
          >
            Request Demo
          </button>
        </div>
      </Container>
      {isVisibleRequestADemo && (
        <GetADemoModal setIsVisible={setIsVisibleRequestADemo} />
      )}
    </React.Fragment>
  )
}