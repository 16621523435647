import React, { useEffect, useState } from "react";
import clsx from "classnames";

import { Input } from "../common/Input";

import styles from "./LearnMoreModal.module.css";

const defaultFormValues = {
  email: "",
  firstName: "",
  phone: "",
  company: "",
};

const learnMoreFormFields = [
  { name: "email", placeholder: "Email*", require: true, type: "email" },
  {
    name: "firstName",
    placeholder: "First Name*",
    require: true,
    type: "text",
  },
  { name: "phone", placeholder: "Phone Number", require: false, type: "phone" },
  { name: "company", placeholder: "Company", require: false, type: "text" },
];

export const LearnMoreForm = ({ setIsSend, status, subscribe }) => {
  const [formValues, setFormValues] = useState(defaultFormValues);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const buttonStyles = clsx("button", "large", styles.button);

  useEffect(() => {
    if (status === "success") {
      setIsSend(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === "success") {
    return (
      <div className="text-center">
        <p>Thank you! Your submission has been received!</p>
      </div>
    );
  }
  return (
    <form
      className={styles.wrapper}
      onSubmit={(event) => {
        event.preventDefault();

        subscribe({
          EMAIL: formValues.email,
          MERGE1: formValues.firstName,
          MERGE4: formValues.phone,
          MERGE6: formValues.company,
        });
      }}
    >
      {learnMoreFormFields.map((field) => (
        <Input
          key={field.name}
          inputClasses={styles.input}
          name={field.name}
          placeholder={field.placeholder}
          required={field.require}
          type={field.type}
          value={formValues[field.name]}
          onChange={handleChange}
        />
      ))}
      <div className={styles.submitWrapper}>
        <input type="submit" value="Submit" className={buttonStyles} />
      </div>
    </form>
  );
};
