import { Container } from "../common";
import { BacklogAndInvoicingCards } from './BacklogAndInvoicingCards';

import styles from './BacklogAndInvoicing.module.css';

export function BacklogAndInvoicing() {
  return (
    <Container wrapperClasses={styles.wrapper}>
      <h2 className="title text-center">Backlog & <span className="text-red">invoicing</span></h2>
      <h5 className={styles.subTitle}>Get paid faster</h5>
      <div className={styles.cardsWrapper}>
        <BacklogAndInvoicingCards/>
      </div>
    </Container>
  )
}