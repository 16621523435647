import React, { useState } from "react";
import clsx from 'classnames';

import { Container } from '../common';
import { LearnMoreModal } from '../LearnMoreModal';

import styles from "./Pricing.module.css";
const titleStyles = clsx(styles.title, "title");

export const Pricing = () => {
  const [isVisibleLearnMore, setIsVisibleLearnMore] = useState(false);

  return (
    <React.Fragment>
      <div className={styles.containerBackground}>
        <Container wrapperClasses={styles.wrapper}>
          <h3 className={titleStyles}>Pricing</h3>
          <h4 className={styles.sectionTitle}>Get current pricing options and a demo today</h4>
          <p className={styles.text}>Monthly and Annual pricing options are available</p>
          <button
            className="button"
            onClick={() => {
              setIsVisibleLearnMore(true);
            }}
          >
            Request Demo & Pricing
          </button>
        </Container>
      </div>
      {isVisibleLearnMore && (
        <LearnMoreModal setIsVisible={setIsVisibleLearnMore} />
      )}
    </React.Fragment>
  );
};
