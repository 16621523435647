import React, { useEffect, useState } from "react";
import clsx from "classnames";

import { Input } from "../common/Input";

import styles from "./GetADemoModal.module.css";

const defaultFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  company: "",
};

const getADemoFormFields = [
  { name: "email", placeholder: "Email*", require: true, type: "email" },
  {
    name: "firstName",
    placeholder: "First Name*",
    require: true,
    type: "text",
  },
  {
    name: "lastName",
    placeholder: "Last Name",
    require: false,
    type: "text",
  },
  {
    name: "phone",
    placeholder: "Phone Number",
    require: false,
    type: "phone",
  },
  {
    name: "company",
    placeholder: "Company",
    require: false,
    type: "text",
  },
];

export const GetADemoForm = ({ subscribe, status, setIsSend }) => {
  const [formValues, setFormValues] = useState(defaultFormValues);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    subscribe({
      EMAIL: formValues.email,
      MERGE1: formValues.firstName,
      MERGE2: formValues.lastName,
      MERGE4: formValues.phone,
      MERGE6: formValues.company,
    });
  };

  const buttonStyles = clsx("button", "large", styles.button);

  useEffect(() => {
    if (status === "success") {
      setIsSend(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === "success") {
    return (
      <div className="text-center">
        <p>Thank you! Your submission has been received!</p>
      </div>
    );
  }

  return (
    <form className={styles.wrapper} onSubmit={onSubmit}>
      {getADemoFormFields.map((field) => (
        <Input
          key={field.name}
          inputClasses={styles.input}
          name={field.name}
          placeholder={field.placeholder}
          required={field.require}
          type={field.type}
          value={formValues[field.name]}
          onChange={handleChange}
        />
      ))}
      <div className={styles.submitWrapper}>
        <input type="submit" value="Send Request" className={buttonStyles} />
      </div>
    </form>
  );
};
