import { useParams } from "react-router-dom";

import { useDocumentTitle } from "../hooks";
import {
  Container,
  SubNavContentContainer,
  NavContent,
  MainContent,
} from "../components/common";

export const LegalPage = () => {
  useDocumentTitle("Legal");
  const { page } = useParams();

  return (
    <Container>
      <SubNavContentContainer
        nav={<NavContent page={page} />}
        content={<MainContent page={page} />}

      />
    </Container>
  )
};