import React from 'react';
import { backlogAndInvoicingCards } from '../../data/backlogAndInvoicingCards';

import styles from './BacklogAndInvoicing.module.css';

export function BacklogAndInvoicingCards() {
  return (
    <React.Fragment>
      {backlogAndInvoicingCards.map(
        ({ image, title }, index) => (
          <div className={styles.card}>
            <div className={styles.cardImageContainer}>
              <img src={image} alt=""/>
            </div>
            <div className={styles.cardInfo}>
              <span className={styles.cardNumber}>{index + 1}</span>
              <div>{title}</div>
            </div>
          </div>
        ))
      }
    </React.Fragment>
  )
}