import React from "react";
import clsx from "classnames";
import { useMatch, Link }  from "react-router-dom";

import { Container } from "../Container";

import { ReactComponent as LinkedinIcon } from "../../../icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../../../icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../../icons/facebook.svg";
import { ReactComponent as LogoIcon } from "../../../icons/logo.svg";

import styles from "./Footer.module.css";

export const Footer = () => {
  const titleStyles = clsx("text-gold", styles.title);
  const descriptionnStyles = clsx("text-white", styles.desc);

  const isLegalPage = useMatch({ path: '/legal/:page' });

  const onInstagramClick = () => {
    window.open("https://www.instagram.com/pittbos_software/", "_blank");
  };

  const onFacebookClick = () => {
    window.open("https://www.facebook.com/Pittbos", "_blank");
  };

  const onLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/pittbos/", "_blank");
  };

  return (
    <Container backgroundDarkBlue>
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <LogoIcon />
          <p className={styles.logoHeading}>
            Built <span className="text-white">by pavers</span> for pavers
          </p>
        </div>
        <div className={styles.findUsContainer}>
          <p className={titleStyles}>Find Us</p>
          <p className={descriptionnStyles}>
            Pittbos LLC <br/>
            127 Cambridge St<br/>
            Burlington, MA  01803<br/>
            617.999.9429
          </p>
          <div className={styles.interactionButtons}>
            <InstagramIcon
              className={styles.links}
              onClick={onInstagramClick}
            />
            <FacebookIcon className={styles.links} onClick={onFacebookClick} />
            <LinkedinIcon className={styles.links} onClick={onLinkedinClick} />
          </div>
        </div>
      </div>
      {
        !isLegalPage &&
        <Link to={'/legal/1'}  className={styles.privacy}>
          Pittbos terms and policies
        </Link>
      }
    </Container>
  );
};
