import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { Modal } from "../common/Modals";

import { LearnMoreForm } from "./LearnMoreForm";

export const LearnMoreModal = ({ setIsVisible }) => {
  const [isSend, setIsSend] = useState(false);

  return (
    <Modal
      description={
        !isSend &&
        "Please enter your contact information and our sales team will be happy to provide you with a free demonstration."
      }
      title={!isSend && "THANK YOU FOR YOUR INTEREST IN PITTBOS!"}
      setIsVisible={setIsVisible}
    >
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={(props) => <LearnMoreForm {...props} setIsSend={setIsSend} />}
      />
    </Modal>
  );
};
